
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import t from "@common/src/i18n/t";
import FileUpload from "@/components/scope/file-upload.vue";
import any = jasmine.any;

const detecationItem = namespace("detecationItem");
const app = namespace("app");
const base = namespace("base");
const service = namespace("service-item");
const marketing = namespace("marketing");


@Component({
  components: { FileUpload }
})
export default class TestItemEdit extends Vue {
  @detecationItem.Action saveDetecationItem;
  @detecationItem.Action getDetecationItemDetail;
  @service.Action getServiceItemList;
  @app.Mutation setPBPX;
  @app.State btnLoading;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  @marketing.Action queryCardTemplateList;
  @detecationItem.Action maintainCalendarSaveOrUpdateProject;
  @detecationItem.Action maintainCalendarProjectInfo;

  //服务列表
  serviceList: any[] = [];
  //套餐卡列表
  packageList: any[] = [];

  packageSearchForm = {
    cardTemplateName: "",
    enableStatus: 1, //（1：启用、0：禁用） 只显示启用的卡
    storeCode: ""
    // cardType: 1 //（1：套餐卡、2：储蓄卡） 0630这个版本先写死
  };
  projectTypeList: any = [
    { dataValue: 1, dataDesc: "洗车-全自动" },
    { dataValue: 2, dataDesc: "养护" },
    { dataValue: 3, dataDesc: "洗车-人工" }
  ];

  definitionOptionRange: any = {
    value: "",
    operator: "",
    operator_desc: ""
  };

  definitionItemService: any = {
    tenantCode: "SYS_DEFAULT",
    orgCode: "SYS_DEFAULT",
    serviceCode: "",
    serviceName: "",
    recommendedReason: ""
  };

  definitionPackageService: any = {
    tenantCode: "SYS_DEFAULT",
    orgCode: "SYS_DEFAULT",
    packageCode: "",
    packageName: "",
    recommendedReason: ""
  };

  definitionRemindService: any = {
    remindPeriod: "",
    remindMode: "",
    verbalTrick: ""
  };

  defaultProjectIconList: any = [];
  defaultHazardUrlList: any = [];

  serviceSelectChange(row) {
    const selectedItem = this.serviceList.find(item => item.serviceCode === row.serviceCode);
    if (selectedItem) {
      row.serviceName = selectedItem.serviceName;
    } else {
      row.serviceName = ""; // 或者根据需要设置为其他默认值
    }
  }

  packageSelectChange(row) {
    const selectedItem = this.packageList.find(item => item.cardTemplateCode === row.packageCode);
    if (selectedItem) {
      row.packageName = selectedItem.cardTemplateName;
    } else {
      row.packageName = ""; // 或者根据需要设置为其他默认值
    }
  }


  handleDeleteServiceItem(row) {
    this.mainInfoFormData.itemService.splice(this.mainInfoFormData.itemService.indexOf(row), 1);
  }

  handleDeletePackageItem(row) {
    this.mainInfoFormData.itemPackage.splice(this.mainInfoFormData.itemPackage.indexOf(row), 1);
  }
  handleDeleteRemindItem(row) {
    this.mainInfoFormData.itemRemind.splice(this.mainInfoFormData.itemRemind.indexOf(row), 1);
  }
  handleAddServiceItem() {
    let item = Object.assign({}, this.definitionItemService);
    this.mainInfoFormData.itemService.push(item);
  }

  handleAddPackageItem() {
    let item = Object.assign({}, this.definitionPackageService);
    this.mainInfoFormData.itemPackage.push(item);
  }


  handleAddRemindItem() {
    let item = Object.assign({}, this.definitionRemindService);
    this.mainInfoFormData.itemRemind.push(item);
  }

  get breadData() {
    return [
      { name: "养车日历项目", path: "/maintenance/detecationItem/list" },
      { name: this.isAdd ? "创建项目" : "修改项目" }
    ];
  }

  get isAdd() {
    return this.$route.name === "detecationItemAdd";
  }

  mainInfoRules: any = {
    projectName: [
      {
        required: true,
        message: "请输入项目名称",
        trigger: "blur"
      }
    ],
    projectCategory: [
      {
        required: true,
        message: "请选择项目类型",
        trigger: "blur"
      }
    ]
  };
  mainInfoFormData: any = {
    id: "",
    projectNo: "",
    name: "",
    remark: "",
    type: "",
    explains: "",
    state: 1,
    reminderFrequency: "",
    recommendedReason: "",
    recommendedWeight: "",
    icon: "",
    itemService: [],
    itemPackage: [],
    itemRemind: []

  };

  get projectNo() {
    let val = this.$route.query.projectNo;
    console.log("projectNo:" + val);
    return (val || "");
  }

  created() {
    this.init();
  }

  init() {
    this.loadProjectDetail();
    this.loadServiceListData();
    this.loadPackageListData();
  }

  fillingOptionRange(optionRange) {
    if (!optionRange[0]) {
      optionRange.push(Object.assign({}, this.definitionOptionRange));
    }
    if (!optionRange[1]) {
      optionRange.push(Object.assign({}, this.definitionOptionRange));
    }
  }

  loadServiceListData() {
    return this.getServiceItemList({
      pageSize: 400,
      pageNum: 1
    }).then(data => {
      this.serviceList = data.data.list;
      return data;
    });
  }

  loadPackageListData() {
    return this.queryCardTemplateList({
      ...this.packageSearchForm,
      pageNum: this.mix_pageNum,
      pageSize: 900
    }).then(data => {
      this.packageList = data.data.list;
      return data;
    });
  }

  loadProjectDetail() {
    if (this.projectNo.length <= 0) {
      return;
    }

    this.maintainCalendarProjectInfo({
      projectNo: this.projectNo
    }).then(data => {
      this.mainInfoFormData = data.data;
      this.defaultProjectIconList = [{ url: this.mainInfoFormData.icon }];
    });
  }

  handleSaveItem() {
    console.log("输出:" + JSON.stringify(this.mainInfoFormData));
    this.mainInfoFormData.recommendedWeight = Number(this.mainInfoFormData.recommendedWeight);
    this.maintainCalendarSaveOrUpdateProject(this.mainInfoFormData)
      .then(data => {
        this.$message.success(t("base.save-success"));
        this.$router.push(`/maintenance/maintainProject/item`);
      })
      .finally(() => {
        this.btnStopLoading();
      });
  }
}
